<template>
    <Dialog header='Manage Plan' v-model:visible="display" :modal="true" :draggable="false" class='manage-plan-modal'>
        <div style="font-size:1rem;">
                        This group is currently on the <strong>{{currentPlan.name}} Plan</strong>.
                        <template v-if="selectedGroup.groupSubscription?.stopDate != null">
                            <span style="color:red;">(ending {{selectedGroup.groupSubscription?.stopDate}})</span>
                        </template>

                        </div>
        
                <div class="billing-container" >
                    <!-- <template v-if='hasBasicPlan()'>
                        <div>
                        You care currently on the <strong>Basic {{getGroupType()}} Plan</strong>.
                        </div>
                    </template> -->
                    <h1 class="header">Premium Group Plans</h1>
                    
                    <div class="switch-container">
                        <div class='plan-container'>
                        <h4 class="switch-title">Monthly</h4>
                        <RadioButton v-model="selectedFrequency" value="Monthly" key="Monthly" />
                        </div>
                        <div class='plan-container'>
                        <h4 class="switch-title">Annual</h4>
                        <RadioButton v-model="selectedFrequency" value="Yearly" key="Annual" />
                        </div>
                    </div>
                    <div class="save-container">
                    <p>Get 2 months free with an annual subscription.</p>
                    </div>
                   
                    <div class="subscriptions-container">

                        <template v-if="isCommunity()">

                            <div class="subscription-card">
                                <div class='subscription-title-container'>
                                    <h3 class="subscription-title">Small Group</h3>
                                    
                                </div>
                                <div class='subscription-description-container'>
                                    <p class="subscription-description">For groups with under 100 members.</p>
                                </div>
                                <div class="price-container">
                                        <h1 class="price"><span>$</span>{{premiumSmallPrice}}</h1>
                                    <p class="currency">USD</p>
                                </div>
                                <p class="frequency">{{selectedFrequency}}</p>
                                <div class='bottom-content-container'>
                                    <ul class="feature-list">
                                        <li class="feature">
                                            <i class="pi pi-check" />
                                            <span class="feature-title">Unlimited Discussion Feeds</span>
                                        </li>
                                        <li class="feature">
                                            <i class="pi pi-check" />
                                            <span class="feature-title">Unlimited Chat Channels</span>
                                            </li>
                                        <li class="feature">
                                            <i class="pi pi-check" />
                                            <span class="feature-title">Maximum of <strong>100</strong> members</span>
                                        </li>
                                        <li class="feature">
                                            <i class="pi pi-check" />
                                            <span class="feature-title">Aggregate Portfolios</span>
                                        </li>
                                        <li class="feature">
                                            <i class="pi pi-check" />
                                            <span class="feature-title">Collaborative Portfolios</span>
                                        </li>
                                        <li class="feature">
                                            <i class="pi pi-check" />
                                            <span class="feature-title">Motion to Invest</span>
                                        </li>
                                        <li class="feature">
                                            <i class="pi pi-check" />
                                            <span class="feature-title">Group Emails</span>
                                        </li>
                                        <li class="feature">
                                            <i class="pi pi-check" />
                                            <span class="feature-title">Competition Hosting (1 free)</span>
                                        </li>
                                        <li class="feature">
                                            <i class="pi pi-check" />
                                            <span class="feature-title">Courses</span>
                                        </li>
                                        
                                        
                                    </ul>
                                    <Button v-if="hasBasicPlan()" :loading="upgrading" class="cta-button upgrade" @click="createCheckoutSession('small')" label="Upgrade"/>
                                    
                                    
                                    <Button  v-else class="cta-button manage" @click="createPortalSession" label="Manage Existing Plan" :loading="upgrading"/>
                                
                                </div>
                            </div>


                            <div class="subscription-card">
                                <div class='subscription-title-container'>
                                    <h3 class="subscription-title">Medium Group</h3>
                                    
                                </div>
                                <div class='subscription-description-container'>
                                    <p class="subscription-description">For groups with under 1000 members.</p>
                                </div>
                                <div class="price-container">
                                        <h1 class="price"><span>$</span>{{premiumMediumPrice}}</h1>
                                    <p class="currency">USD</p>
                                </div>
                                <p class="frequency">{{selectedFrequency}}</p>
                                <div class='bottom-content-container'>
                                    <ul class="feature-list">
                                        <li class="feature">
                                            <i class="pi pi-check" />
                                            <span class="feature-title">Unlimited Discussion Feeds</span>
                                        </li>
                                        <li class="feature">
                                            <i class="pi pi-check" />
                                            <span class="feature-title">Unlimited Chat Channels</span>
                                            </li>
                                        <li class="feature">
                                            <i class="pi pi-check" />
                                            <span class="feature-title">Maximum of <strong>1000</strong> members</span>
                                        </li>
                                        <li class="feature">
                                            <i class="pi pi-check" />
                                            <span class="feature-title">Aggregate Portfolios</span>
                                        </li>
                                        <li class="feature">
                                            <i class="pi pi-check" />
                                            <span class="feature-title">Collaborative Portfolios</span>
                                        </li>
                                        <li class="feature">
                                            <i class="pi pi-check" />
                                            <span class="feature-title">Motion to Invest</span>
                                        </li>
                                        <li class="feature">
                                            <i class="pi pi-check" />
                                            <span class="feature-title">Group Emails</span>
                                        </li>
                                        <li class="feature">
                                            <i class="pi pi-check" />
                                            <span class="feature-title">Competition Hosting (2 free)</span>
                                        </li>
                                        <li class="feature">
                                            <i class="pi pi-check" />
                                            <span class="feature-title">Courses</span>
                                        </li>
                                    </ul>
                                    <Button v-if="hasBasicPlan()" :loading="upgrading" class="cta-button upgrade" @click="createCheckoutSession('medium')" label="Upgrade"/>
                                    
                                    
                                    <Button  v-else class="cta-button manage" @click="createPortalSession" label="Manage Existing Plan" :loading="upgrading"/>
                                
                                </div>
                            </div>
                            <div class="subscription-card">
                                <div class='subscription-title-container'>
                                    <h3 class="subscription-title">Large Group</h3>
                                    
                                </div>
                                <div class='subscription-description-container'>
                                    <p class="subscription-description">For groups with over 1000 members.</p>
                                </div>
                                <div class="price-container">
                                        <h1 class="price"><span>$</span>{{premiumLargePrice}}</h1>
                                    <p class="currency">USD</p>
                                </div>
                                <p class="frequency">{{selectedFrequency}}</p>
                                <div class='bottom-content-container'>
                                    <ul class="feature-list">
                                        <li class="feature">
                                            <i class="pi pi-check" />
                                            <span class="feature-title">Unlimited Discussion Feeds</span>
                                        </li>
                                        <li class="feature">
                                            <i class="pi pi-check" />
                                            <span class="feature-title">Unlimited Chat Channels</span>
                                            </li>
                                        <li class="feature">
                                            <i class="pi pi-check" />
                                            <span class="feature-title">Unlimited members</span>
                                        </li>
                                        <li class="feature">
                                            <i class="pi pi-check" />
                                            <span class="feature-title">Aggregate Portfolios</span>
                                        </li>
                                        <li class="feature">
                                            <i class="pi pi-check" />
                                            <span class="feature-title">Collaborative Portfolios</span>
                                        </li>
                                        <li class="feature">
                                            <i class="pi pi-check" />
                                            <span class="feature-title">Motion to Invest</span>
                                        </li>
                                        <li class="feature">
                                            <i class="pi pi-check" />
                                            <span class="feature-title">Group Emails</span>
                                        </li>
                                        <li class="feature">
                                            <i class="pi pi-check" />
                                            <span class="feature-title">Competition Hosting (3 free)</span>
                                        </li>
                                        <li class="feature">
                                            <i class="pi pi-check" />
                                            <span class="feature-title">Courses</span>
                                        </li>
                                    </ul>
                                   
                                       
                                    <Button v-if="hasBasicPlan()" :loading="upgrading" class="cta-button upgrade" @click="createCheckoutSession('large')" label="Upgrade"/>
                                    
                                    
                                    <Button  v-else class="cta-button manage" @click="createPortalSession" label="Manage Existing Plan" :loading="upgrading"/>
                                    
                                
                                </div>
                            </div>
                        </template>
                        <template v-else>
                            <div class="subscription-card recommended">
                                <div class='subscription-title-container'>
                                    <h3 class="subscription-title">Premium Team</h3>
                                    
                                </div>
                                <div class='subscription-description-container'>
                                    <p class="subscription-description">For teams that want more tools.</p>
                                </div>
                                <div class="price-container">
                                        <h1 class="price"><span>$</span>{{premiumTeamPrice}}</h1>
                                    <p class="currency">USD</p>
                                </div>
                                <p class="frequency">{{selectedFrequency}}</p>
                                <div class='bottom-content-container'>
                                    <ul class="feature-list">
                                        <li class="feature">
                                        <i class="pi pi-check" />
                                        <span class="feature-title">Unlimited Discussion Feeds</span>
                                        </li>
                                        <li class="feature">
                                        <i class="pi pi-check" />
                                        <span class="feature-title">Unlimited Chat Channels</span>
                                        </li>
                                        <li class="feature">
                                        <i class="pi pi-check" />
                                        <span class="feature-title">Aggregate Portfolios</span>
                                        </li>
                                        <li class="feature">
                                        <i class="pi pi-check" />
                                        <span class="feature-title">Collaborative Portfolios</span>
                                        </li>
                                        <li class="feature">
                                        <i class="pi pi-check" />
                                        <span class="feature-title">Motion to Invest</span>
                                        </li>
                                        <li class="feature">
                                        <i class="pi pi-check" />
                                        <span class="feature-title">Team Emails</span>
                                        </li>
                                        
                                        
                                    </ul>
                                    <Button v-if="!hasPremiumTeamSubscription()" :loading="upgrading" class="cta-button upgrade" @click="createCheckoutSession('team')" label="Upgrade"/>
                                    
                                    
                                    <Button  v-else class="cta-button manage" @click="createPortalSession" label="Manage Plan" :loading="upgrading"/>
                                
                                </div>
                            </div>    
                        </template>
                    
                    </div>
                </div>
            
	</Dialog>
</template>

<script>
import Dialog from 'primevue/dialog';
import PaymentService from '../../../service/PaymentService';
import RadioButton from 'primevue/radiobutton';
import Button from 'primevue/button';
import { GroupPlanUtils} from '../';

export default {
    name: 'ManagePlanModal',
    emits: ['wad-selected'],
    components: {
        RadioButton,
        Button,
        Dialog,
    },
    data() {
        return {
            display: false,
            currentPlan: null,
            selectedGroup: null,
            selectedFrequency: "Monthly",
            upgrading:false,
        }
    },

    computed: {
        premiumSmallPrice() {
            if (this.selectedFrequency === 'Yearly') {
                return "199.50";
            } else {
                return "19.95";
            }
        },
        premiumMediumPrice() {
            if (this.selectedFrequency === 'Yearly') {
                return "299.50";
            } else {
                return "29.95";
            }
        },
        premiumLargePrice() {
            if (this.selectedFrequency === 'Yearly') {
                return "399.50";
            } else {
                return "39.95";
            }
        },
        premiumTeamPrice() {
            if (this.selectedFrequency === 'Yearly') {
                return "49.50";
            } else {
                return "4.95";
            }
        },

    },


    methods: {
        isCommunity() {
            return this.selectedGroup?.groupTypeId == 1;
        },

        getGroupType() {
            if( this.selectedGroup?.groupTypeId == 1) {
                return "Community";
            }
            else  {
                return "Team";
            }
        },

        hasPremiumLargeSubscription() {
            return GroupPlanUtils.isPremiumLargePlan(this.currentPlan);
        },
        hasPremiumMediumSubscription() {
            return GroupPlanUtils.isPremiumMediumPlan(this.currentPlan);
        },
        hasPremiumSmallSubscription() {
            return GroupPlanUtils.isPremiumSmallPlan(this.currentPlan);
        },
        hasBasicPlan() {
            return GroupPlanUtils.isBasicPlan(this.currentPlan);
        },
        hasPremiumTeamSubscription() {
            return GroupPlanUtils.isPremiumTeamPlan(this.currentPlan);
        },

        groupHasPremiumSubscription() {
            return GroupPlanUtils.hasPremiumGroupSubscription(this.selectedGroup.subscriptionId);
        },

        getGroupPlan() {
            return GroupPlanUtils.getGroupSubscription(this.selectedGroup.subscriptionId);
        },
        open(group) {
            this.selectedGroup = group;
            this.currentPlan = this.getGroupPlan();
            this.display = true;
            
        },

        onClose() {
            this.display = false;
            this.currentPlan = null;
            this.selectedGroup = null;
            this.selectedFrequency = "Monthly";
        },

       createCheckoutSession(planType) {
        this.upgrading = true;
        let plan = GroupPlanUtils.getPlan(planType, this.selectedFrequency);

        PaymentService.createGroupPlanCheckoutSession(this.selectedGroup.groupId, plan.id).then(resp => {
            if (resp.data.status === 'success') {
                window.location.href = resp.data.url;
            
            } else {
                if( resp.data.message == 'Only one group subscription allowed.' ) {
                    this.$toast.add({ severity: 'error', summary: 'Only one group subscription allowed per account.', 
                        detail: "Currently you can only have one premium group subscription per account.  To create another group with a premium subscription please create another user account.", life: 9000, group: 'tc' });
                }
                else if( resp.data.message == 'Too many members.' ) {
                    this.$toast.add({ severity: 'error', summary: 'Too many members.', 
                        detail: "Your group has too many members for the subscription selected.", life: 5000 });
                }
                else {
                    this.$toast.add({ severity: 'error', summary: 'Error launching checkout screen. Contact us if the issue persists.', detail: resp.data.message, life: 5000, group: 'tc' });
                }
                this.upgrading =false;
            }
        }).catch(err => {
            console.log(err);
            this.$toast.add({ severity: 'error', summary: 'Error launching checkout screen. Contact us if the issue persists.', detail: err.message, life: 5000, group: 'tc' });
            this.upgrading =false;
        });
    },

    createPortalSession() {
        this.upgrading = true;
        PaymentService.createGroupPortalSession(this.selectedGroup.groupId).then(resp => {
            if (resp.data.status === 'success') {
            window.location.href = resp.data.url;
            } else {
            this.$toast.add({ severity: 'error', summary: 'Error launching customer portal. Contact us if the issue persists.', detail: resp.message, life: 3000 });
            this.upgrading =false;
            }
        }).catch(err => {
            console.log(err);
            this.$toast.add({ severity: 'error', summary: 'Error launching customer portal. Contact us if the issue persists.', detail: err.message, life: 3000 });
            this.upgrading =false;
        });
    },

        
    },

}
</script>

<style>
.manage-plan-modal {
    font-family: "Trebuchet MS", "Verdana";
    /*width: 350px;*/
}

.manage-plan-modal .p-dialog-header {
    padding: 16px;
}

/* .manage-plan-modal .p-dialog-content {
    padding: 0 16px 16px;
    overflow-y: hidden;
    text-align: center;
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
} */



</style>
<style scoped>
*:not(.pi),::v-deep(.p-component) {
	font-family: "Trebuchet MS", "Verdana";
}

.p-dialog-header .title {
    font-weight: bold;
    color: #32364e;
    font-size: 24px;
}

.body-text {
    color: black;
    margin-bottom: 16px;
}



.buttons-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.p-button {
    border-radius: 16px;
    margin-bottom: 8px;
}
.p-button:last-of-type {
    margin-bottom: 0;
}

@media (max-width: 760px) {

}


.billing-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  /* padding: 24px 0px; */
  font-family: 'Trebuchet MS';
}

.header {
  font-size: 24px;
  color: #32364e;
  text-align: center;
  margin-bottom: 14px;
}

.details-link {
  text-decoration: underline;
  font-size: 16px;
  cursor: pointer;
  margin-bottom: 26px;
}

.details-link:hover {
  color: #3c9;
}

.switch-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 24px;
}
.switch-title {
  margin: 0 16px 0 0;
  font-size: 18px;
  font-weight: bold;
  color: #32364e;
}

.plan-container {
  display: flex;
  margin-right: 16px;
}
.plan-container:last-child {
  margin-right: 0px;
}

.save-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 36px;
  padding: 10px 30px;
  border: 1px solid #3c9;
  border-radius: 8px;
  background-color: rgba(51, 204, 153, 0.427);
  color: #32364e;
  font-size: 14px;
}

.subscriptions-container {
  display: flex;
  align-items: flex-start;
  justify-content: center;
}

.subscription-card {
  display: flex;
  flex-direction: column;
  padding: 16px 20px;
  background-color: #fff;
  border-radius: 16px;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2);
  margin-right: 24px;
  width: 300px;
  /* height: 560px; */
}

.subscription-card.recommended {
  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.14), 0px 1px 18px rgba(0, 0, 0, 0.12), 0px 3px 5px rgba(0, 0, 0, 0.2);
  border: 1px solid #3c9;
}

.subscription-card:last-child {
  margin-right: 0px;
}

.subscription-title-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 12px;
}
.subscription-title-container .subscription-title {
  margin-bottom: 4px;
}

.subscription-title {
  color: #32364e;
  text-align: center;
}

.subscription-title > h3 {
    font-size: 1.5rem;
}

/* .subscription-title span {
  padding: 4px 8px;
  background-color: #693BF5;
  color: #fff;
  border-radius: 500px;
  font-size: 10px;
  text-transform: uppercase;
  position: absolute;
  transform: translate(8%, 8%);
} */

.subscription-pill-row {
  display: flex;
}

.subscription-pill {
  padding: 4px 8px;
  margin-right: 4px;
  color: #fff;
  border-radius: 500px;
  font-size: 10px;
  text-transform: uppercase;
}
.subscription-pill:last-child {
  margin-right: 0;
}
.subscription-pill.recommended {
  background-color: #693BF5;
}
.subscription-pill.current {
  background-color: #32364e;
}
.subscription-pill.trial-expired {
  background-color: #e63e3e;
}

.subscription-description-container {
  color: black;
  text-align: center;
  margin-bottom: 16px;
}
.subscription-description-container p {
  margin-bottom: 0;
}

.price-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.price {
  margin: 0;
  margin-right: 6px;
  color: #32364e;
  line-height: 1;
}

.price span {
  font-size: 16px;
  vertical-align: super;
  /* position: absolute; */
  /* transform: translate(-100%, 50%); */
}

.frequency {
  color: #999;
  text-align: center;
  margin-bottom: 24px;
}

.feature-list {
  list-style: none;
  margin-bottom: 24px;
}
.feature-list:last-child {
  margin-bottom: 0px;
}

.pi-check {
  color: #3c9;
  margin-right: 8px;
  margin-bottom: 12px;
}

.cta-button {
  width: 100%;
  border: none;
  padding: 12px 24px;
  border-radius: 4px;
  background-color: #3c9;
  color: #fff;
  cursor: pointer;
  font-family: inherit;
  font-weight: bold;
  font-size: 16px;
}

.cta-button:hover {
  opacity: 0.9;
}

.manage {
  background-color: #32364e;
}

.downgrade {
  background-color: #e63e3e;
}

.upgrade {
  background-color: #3c9;
}

.trial-text {
  color: #999;
  font-size: 12px;
  text-align: center;
  display: flex;
  justify-content: center;
  margin-top: 8px;
  text-decoration: underline;
  cursor: pointer;
}

.trial-text:hover {
  color: #3c9;
}

.bottom-content-container {
  /* margin-top: auto; */
}
</style>